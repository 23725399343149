<script>
import Toast from "@/services/swal2/mixins";
import Vuex from "vuex";

// const axios = require('axios');

export default {
    name: "ContentGenerator",
    data() {
        return {
            generatedTweets: [],
            showPreview: false,
            twitClient: null,
            selectedPlatform:'blog',
            selectedLanguage:['french'],
            selectedLength:'long',
            selectedLocality:null,
            selectedAdministrativeAreaLvl1:null,
            selectedAdministrativeAreaLvl2:null,
            selectedCountry:null,
            selectedOptions:'locality',
            selectedModelTemperature: 0.8,
            optionsPlatform: [
                { text: 'Facebook', value: 'facebook' },
                { text: 'Twitter', value: 'twitter' },
                { text: 'Blog', value: 'blog' },
                { text: 'Libre', value: null }
            ],
            optionsLanguage: [
                { text: 'Français', value: 'french' },
                { text: 'English', value: 'english' },
                { text: 'Toutes', value: 'all' },
            ],
            optionsLength: [
                { text: 'Long', value: 'long' },
                { text: 'Moyen', value: 'medium' },
                { text: 'Court', value: 'short' },
                { text: 'Libre', value: null }
            ],
            optionsGeo: [
                { text: 'Ville', value: 'locality' },
                { text: 'Département', value: 'administrativeAreaLvl1' },
                { text: 'Région', value: 'administrativeAreaLvl2' },
                { text: 'Pays', value: 'countryLongName' }
            ],
            optionModelTemperature: [
                { text: 'Créative', value: 0.8 },
                { text: 'Equilibrée', value: 0.4 },
                { text: 'Stricte', value: 0.2 }
            ],
            ongoing:false,
            finished:false,
        };
    },
    computed:{
        ...Vuex.mapGetters('contentGeneration', {
          generatedContent:'getContent',
          getLocations:'getLocations'
        }),
        optionCountry(){
            let countries = this.getLocations.countries
            let options = []

            for (let i = 0; i < countries.length; i++) {
              if (countries[i] !== null && countries[i] !== 'null'){

                options.push({ text: countries[i], value: countries[i] })
              }
            }

            return options
        },
        optionAdministrativeAreaLvl1(){
            let administrativesAreaLvl1 = this.getLocations.administrativesAreaLvl1
            let options = []

            for (let i = 0; i < administrativesAreaLvl1.length; i++) {
              if (administrativesAreaLvl1[i] !== null && administrativesAreaLvl1[i] !== 'null'){
                options.push({ text: administrativesAreaLvl1[i], value: administrativesAreaLvl1[i] })
              }
            }

            return options
        },
        optionAdministrativeAreaLvl2(){
            let administrativesAreaLvl2 = this.getLocations.administrativesAreaLvl2
            let options = []

            for (let i = 0; i < administrativesAreaLvl2.length; i++) {
              if (administrativesAreaLvl2[i] !== null && administrativesAreaLvl2[i] !== 'null'){
                options.push({ text: administrativesAreaLvl2[i], value: administrativesAreaLvl2[i] })
              }
            }

            return options
        },
        optionLocalities(){
            let localities = this.getLocations.localities
            let options = []

            for (let i = 0; i < localities.length; i++) {
              if (localities[i] !== null && localities[i] !== 'null'){
                options.push({ text: localities[i], value: localities[i] })
              }
            }
            return options
        }

    },
    async created() {
        await this.setLocations()
    },
    methods: {
        ...Vuex.mapActions('contentGeneration', {
            setContent:'setContent',
            setContentWithData:'setContentWithData',
            setLocations:'setLocations'

        }),
        async generateContent(data = null){
            this.ongoing = true
            let params = {
              'platform':this.selectedPlatform,
              'length':this.selectedLength,
              'lang':this.selectedLanguage,
              'locality':this.selectedLocality,
              'localityType':this.selectedOptions,
              'temperature':this.selectedModelTemperature
            }

            if (!data){
              await this.setContent(params)
            } else {
              let form = new FormData()
              form.append('data', data)
              await this.setContentWithData([form,params])

            }
            this.ongoing = false
            this.finished = true

            setTimeout(()=> {
                this.finished = false
                this.ongoing = false
            }, 5000)
        },
        // generateTweets2() {
        //     // Array of pollen and their potential risks
        //     var pollenData = [
        //         ['Oak Pollen', 'High risk of allergies'],
        //         ['Grass Pollen', 'Moderate risk of allergies'],
        //         ['Ragweed Pollen', 'Low risk of allergies']
        //     ];
        //
        //     this.generatedTweets = [];
        //
        //     // Iterate over the pollen data
        //     for (var i = 0; i < pollenData.length; i++) {
        //         var pollenName = pollenData[i][0];
        //         var pollenRisk = pollenData[i][1];
        //
        //         // Create tweet content
        //         var tweet = {
        //             id: i + 1,
        //             content: '🌼 ' + pollenName + ' 🌼\n' +
        //                 'Potential risk: ' + pollenRisk + '\n' +
        //                 '#pollen #allergies'
        //         };
        //
        //         // Add tweet to the generatedTweets array
        //         this.generatedTweets.push(tweet);
        //     }
        // },
        // async generateTweets() {
        //     // Array of pollen and their potential risks
        //     var pollenData = [
        //         ['Oak Pollen', 'High risk of allergies'],
        //         ['Grass Pollen', 'Moderate risk of allergies'],
        //         ['Ragweed Pollen', 'Low risk of allergies']
        //     ];
        //
        //     this.generatedTweets = [];
        //
        //     // Iterate over the pollen data
        //     for (var i = 0; i < pollenData.length; i++) {
        //         var pollenName = pollenData[i][0];
        //         var pollenRisk = pollenData[i][1];
        //
        //         // Generate tweet content using ChatGPT API
        //         var tweetContent = await this.generateTweetContent(pollenName, pollenRisk);
        //
        //         // Create tweet object
        //         var tweet = {
        //             id: i + 1,
        //             content: tweetContent,
        //         };
        //
        //         // Add tweet to the generatedTweets array
        //         this.generatedTweets.push(tweet);
        //     }
        // },
        // async generateTweetContent(pollenName, pollenRisk) {
        //     try {
        //         // Make API request to ChatGPT API
        //         const response = await axios.post('https://api.openai.com/v1/chat/completions', {
        //             model: 'gpt-3.5-turbo',
        //             messages: [
        //                 { role: 'system', content: 'You are a Twitter user.' },
        //                 { role: 'user', content: `What are the potential risks of ${pollenName}?` },
        //                 { role: 'assistant', content: `The potential risk of ${pollenName} is ${pollenRisk}.` },
        //                 { role: 'user', content: 'Generate tweet.' },
        //                 { role: 'assistant', content: '' },
        //             ],
        //             max_tokens: 30,
        //             temperature: 0.7,
        //         }, {
        //             headers: {
        //                 'Content-Type': 'application/json',
        //                 'Authorization': 'Bearer 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',
        //             },
        //         });
        //
        //         // Extract the assistant's reply from the API response
        //         const { choices } = response.data;
        //         const assistantReply = choices[choices.length - 1].message.content;
        //
        //         // Remove unnecessary parts and return the generated tweet content
        //         return assistantReply.replace(/(You are a Twitter user\.|Generate tweet\.)/g, '').trim();
        //     } catch (error) {
        //         console.error('Error generating tweet content:', error);
        //         return '';
        //     }
        // },
        // publishTweet() {
        //     if (this.tweetContent) {
        //         this.twitClient.post(
        //             'statuses/update',
        //             { status: this.tweetContent },
        //             (err, data) => {
        //                 if (err) {
        //                     console.log('Error posting tweet:', err);
        //                 } else {
        //                     console.log('Tweet posted successfully:', data.text);
        //                     this.tweetContent = '';
        //                     this.generatedTweets = [];
        //                     this.showPreview = false;
        //                 }
        //             }
        //         );
        //     }
        // },
        copyContent(i) {
            navigator.clipboard.writeText(this.generatedContent[i].content.choices[0].message.content);
            Toast.fire('Le contenu a été copié!','',"success")
        }
    },
}
</script>

<template>
    <b-container fluid>
        <b-row class="justify-content-center align-items-center align-content-center">
            <h3 class="text-center clr-darker-grey">Générateur de Contenu Pollen par IA</h3>
        </b-row>
        <b-row class="my-2 shadow bg-white p-2 rounded">
            <b-col cols="3">
                <b-form-group label="Plateforme : " v-slot="{ ariaDescribedby }" class="mr-5">
                <b-form-radio-group
                        id="radio-group-1"
                        v-model="selectedPlatform"
                        :options="optionsPlatform"
                        :aria-describedby="ariaDescribedby"
                        name="radio-options-1"
                        class="clr-dark-grey"
                        stacked
                />
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group label="Langue(s) : " v-slot="{ ariaDescribedby }" class="mr-5">
                <b-form-checkbox-group
                        id="checkbox-group-1"
                        v-model="selectedLanguage"
                        :options="optionsLanguage"
                        :aria-describedby="ariaDescribedby"
                        name="radio-options-2"
                        class="clr-dark-grey"
                        stacked
                ></b-form-checkbox-group>
            </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group label="Longueur du contenu : " v-slot="{ ariaDescribedby }" class="mr-5">
                    <b-form-radio-group
                            id="radio-group-3"
                            v-model="selectedLength"
                            :options="optionsLength"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-3"
                            class="clr-dark-grey"
                            stacked
                    ></b-form-radio-group>
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group label="Créativité du model : " v-slot="{ ariaDescribedby }" class="mr-5">
                    <b-form-radio-group
                            id="radio-group-4"
                            v-model="selectedModelTemperature"
                            :options="optionModelTemperature"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-4"
                            class="clr-dark-grey"
                            stacked
                    ></b-form-radio-group>
                </b-form-group>
            </b-col>
            <b-col cols="6" v-if="Object.keys(getLocations).length > 0">
                <b-row>
                    <b-form-group label="Localité : " v-slot="{ ariaDescribedby }" class="mx-3">
                        <b-form-radio-group
                                id="radio-group-5"
                                v-model="selectedOptions"
                                :options="optionsGeo"
                                @change="selectedLocality = ''"
                                :aria-describedby="ariaDescribedby"
                                name="radio-options-5"
                                class="clr-dark-grey"
                                stacked
                        ></b-form-radio-group>
                    </b-form-group>
                    <b-col cols="6" v-if="selectedOptions === 'locality'">
                        <p> Ville </p>
                        <b-form-select
                                :options="optionLocalities"
                                v-model="selectedLocality"
                        ></b-form-select>
                    </b-col>
                    <b-col cols="6" v-else-if="selectedOptions === 'administrativeAreaLvl1'">
                        <p> Département </p>
                        <b-form-select
                                :options="optionAdministrativeAreaLvl1"
                                v-model="selectedLocality"
                        ></b-form-select>
                    </b-col>
                    <b-col cols="6" v-else-if="selectedOptions === 'administrativeAreaLvl2'">
                        <p> Région </p>
                        <b-form-select
                                :options="optionAdministrativeAreaLvl2"
                                v-model="selectedLocality"
                        ></b-form-select>
                    </b-col>
                    <b-col cols="6" v-else-if="selectedOptions === 'countryLongName'">
                        <p> Pays </p>
                        <b-form-select
                                :options="optionCountry"
                                v-model="selectedLocality"
                        ></b-form-select>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="justify-content-center align-content-center shadow bg-white rounded p-2">
            <b-col cols="12" class="text-center mb-3">
                <h5 class="font-weight-bolder">Paramètres de générations</h5>
                <h2>
                    <b-badge variant="primary" class="h2">{{ optionsPlatform.find(item => item.value === selectedPlatform).text }}</b-badge> <b-badge variant="primary">{{ optionModelTemperature.find(item => item.value === selectedModelTemperature).text }}</b-badge> <b-badge variant="primary"> {{ optionsLength.find(item => item.value === selectedLength).text }}</b-badge> <b-badge variant="primary" v-for="(lang,i) in optionsLanguage.filter(item => selectedLanguage.includes(item.value))" :key="i">{{ lang.text }} </b-badge> <b-badge variant="primary" class="mr-2">{{ selectedLocality }}</b-badge>
                </h2>
            </b-col>
            <b-col cols="12" class="text-center mb-2">
                <b-btn @click="generateContent" size="lg" class="border-orange bg-white clr-orange text-center" :class="ongoing ? 'ongoing':''">
                    <span v-if="!ongoing && !finished && generatedContent.length > 0"> Générer à nouveau</span>
                    <span v-else-if="!ongoing && !finished"> Lancer la Génération</span>
                    <span v-else-if="ongoing && !finished"> En cours de génération <b-spinner></b-spinner></span>
                </b-btn>
            </b-col>
            <b-card v-for="(generatedText,i) in generatedContent"
                    :key="i"
                    class="mb-2"
            >
                <b-row>
                    <b-col cols="6" class="">
                        <h5>TEXT#{{ generatedContent.length - i }}</h5>
                        <b-badge variant="primary">{{ optionsPlatform.find(item => item.value === generatedText.params.platform).text }}</b-badge> <b-badge variant="primary">{{ optionModelTemperature.find(item => item.value === generatedText.params.temperature).text }}</b-badge> <b-badge variant="primary">{{ optionsLength.find(item => item.value === generatedText.params.length).text }}</b-badge> <b-badge variant="primary" v-for="(lang,i) in optionsLanguage.filter(item => generatedText.params.lang.includes(item.value))" :key="i">{{ lang.text }} </b-badge> <b-badge variant="primary" class="mr-2" >{{ generatedText.params.locality }} </b-badge>
                    </b-col>
                    <b-col cols="6" class="justify-content-end d-flex">
                      <b-row>
                        <b-btn variant="primary" class="mr-2 h-auto bg-white clr-orange" @click="generateContent(generatedText.prompt.content)">Relancer le même prompte <b-icon icon="arrow-repeat" class="clr-orange"></b-icon></b-btn>
                        <b-btn variant="primary" @click="copyContent">Copier <b-icon icon="file-earmark" class="clr-white"></b-icon></b-btn>
                      </b-row>

                    </b-col>
                </b-row>
<!--                <p @click="copyContent(i)" class="card-link align-right"></p>-->
                <b-card-text v-for="(choice,j) in generatedText.content.choices" :key="j">
                    <div v-html="separeteLangText" v-for="(separeteLangText,k) in choice.message.content.split('||')" :key="k" class="mb-2"></div>
                    <b-row class="align-self-end justify-content-end justify-content-end">
                      <p class="float-right clr-darker-grey">caractères: <span class="clr-orange">{{ choice.message.content.length }}</span></p>
                    </b-row>
                    <b-row class="bg-lighter-grey p-3" v-for="(prompt,p) in generatedText.prompt" :key="p">
                      <span class="clr-darker-grey bold">Prompt : </span>
                      {{ prompt.content }}
                    </b-row>
                </b-card-text>
            </b-card>
        </b-row>
    </b-container>
</template>

<style>
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.input-field {
    width: 100%;
    height: 100px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    resize: vertical;
}

.button {
    display: inline-block;
    padding: 10px 20px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #ffffff;
    background-color: #007bff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #0056b3;
}

.tweet-list {
    margin-top: 20px;
}

.tweet {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    background-color: #f5f5f5;
}

.ongoing {
    transform: scale(1);
    animation: pulse 2s infinite;
    border-color: white;
    background-color: white;
}

.finished {

}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
</style>
